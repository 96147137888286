import * as React from "react";
import styled from "styled-components/macro";

export const Note: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return <NoteStyle>{children}</NoteStyle>;
};

const NoteStyle = styled.div`
  position: relative;
  font-family: inherit;
  color: #3c4257;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  word-break: break-word;
  padding: 2.7rem 1rem 1.2rem 1rem;
  margin: 0 0 1.5rem;
  border: 1.5px solid #ffc83c;
  border-radius: 4px 4px 0px 4px;
  &::before {
    content: "Note!";
    position: absolute;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.03rem;
    color: #ffc83c;
    top: 0.8rem;
    left: 1rem;
  }
  &::after {
    content: "";
    position: absolute;
    right: -1.7px;
    bottom: -1.7px;
    width: 1.3rem;
    height: 1.3rem;
    background: linear-gradient(-45deg, white 51%, #ffc83c 51%);
  }
`;
