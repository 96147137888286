import * as React from "react";
import styled from "styled-components/macro";

import * as MDXElements from "./components/mdx-elements";
import { FullWidthSpinner } from "./components/spinner";

const { globalElements, ...components } = MDXElements;

interface RenderNotionViewProps {
  slug: string;
}

const importNotionMDXContent = (slug: string) =>
  React.lazy(() => import(`./notions/${slug}.mdx`)) as unknown as Promise<
    React.ComponentType<{ components: typeof components }>
  >;

const RenderNotionView: React.FC<RenderNotionViewProps> = ({ slug }) => {
  const [notion, setNotion] = React.useState<JSX.Element>();

  React.useEffect(() => {
    const viewPromise = () => {
      const componentPromises = async () => {
        const MDXContent = await importNotionMDXContent(slug);
        return <MDXContent components={components} />;
      };
      componentPromises().then(setNotion);
    };

    // Only try to import notion view when there is a slug
    if (slug) {
      viewPromise();
    }
  }, [slug]);

  return (
    <React.Suspense
      fallback={
        <NotionWrapper>
          <FullWidthSpinner />
        </NotionWrapper>
      }
    >
      <NotionWrapper>
        <NotionContainer>{notion}</NotionContainer>
      </NotionWrapper>
    </React.Suspense>
  );
};

export default RenderNotionView;

export const NotionWrapper = styled.div`
  grid-column: 1 / -1;
  background-color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
`;

export const NotionContainer = styled.div`
  ${globalElements}
  flex: 1;
  color: #19193b;
  overflow-wrap: break-word;
  width: 100%;
  max-width: 43rem;
`;
