import * as React from "react";
import {
  Icon,
  IconButton,
  IconButtonProps,
  ButtonProps,
  Button,
} from "@chakra-ui/react";
import { SpriteMap } from "use-sound/dist/types";

import { RiVolumeUpLine, RiStopFill } from "react-icons/ri";
import { useExtendedSound } from "./use-extended-sound-hook";

interface BaseProps {
  /**
   * Link to the audio to play
   */
  src: string;
  sprite?: SpriteMap;
}

export type AudioButtonProps = BaseProps & ButtonProps;
export interface IconAudioButtonProps
  extends BaseProps,
    Omit<IconButtonProps, "aria-label"> {
  "aria-label"?: string;
}

export const IconAudioButton = React.forwardRef<
  HTMLButtonElement,
  IconAudioButtonProps
>(function IconAudioButton(
  { "aria-label": ariaLabel, src: audioUrl, sprite = {}, ...rest },
  ref,
) {
  const { isLoading, isError, isPlaying, handlePlaySound, handleStopSound } =
    useExtendedSound({ audioUrl, sprite });

  // Hide the button if there is an error
  if (isError) {
    return null;
  }

  return (
    <IconButton
      ref={ref}
      colorScheme="orange"
      aria-label={ariaLabel || "Play Audio"}
      onClick={isPlaying ? handleStopSound : handlePlaySound}
      size="for-icon:md"
      isRound
      isLoading={isLoading}
      isDisabled={isError}
      _loading={{ opacity: 1 }}
      {...rest}
    >
      <Icon as={isPlaying ? RiStopFill : RiVolumeUpLine} />
    </IconButton>
  );
});

export const AudioButton = React.forwardRef<
  HTMLButtonElement,
  AudioButtonProps
>(function AudioButton({ src: audioUrl, sprite = {}, ...rest }, ref) {
  const { isLoading, isError, isPlaying, handlePlaySound, handleStopSound } =
    useExtendedSound({ audioUrl, sprite });

  // Hide the button if there is an error
  if (isError) {
    return null;
  }

  return (
    <Button
      ref={ref}
      colorScheme="orange"
      onClick={isPlaying ? handleStopSound : handlePlaySound}
      isLoading={isLoading}
      isDisabled={isError}
      _loading={{ opacity: 1 }}
      leftIcon={<Icon as={isPlaying ? RiStopFill : RiVolumeUpLine} />}
      {...rest}
    >
      {isPlaying ? "Stop" : "Listen"}
    </Button>
  );
});
