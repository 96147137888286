import * as React from "react";
import { Text, TextProps, HStack, StackProps } from "@chakra-ui/react";

export const Duration: React.FC<TextProps> = (props) => (
  <Text color="black" fontSize="xs" {...props} />
);

export const Time: React.FC<TextProps> = (props) => (
  <Text color="black" fontSize="xs" {...props} />
);

export const PlaybackDuration: React.FC<StackProps> = (props) => (
  <HStack
    spacing={4}
    pos="absolute"
    left={0}
    right={0}
    bottom="-0.2rem"
    align="center"
    justify="space-between"
    {...props}
  />
);
