import romanizeText from "@natanada/romanize";
import isString from "lodash/isString";

import { hangulToRuby, ReturnedHangulWithRuby } from "../../../lib/hangul";

export const isValidText = (text: string): boolean => isString(text);

export const getRomanizedWords = (text: string): ReturnedHangulWithRuby[] => {
  return hangulToRuby(text)
    .filter(Boolean)
    .map((value) => {
      if (typeof value === "object") {
        return {
          ...value,
          text: romanizeText(value.ruby),
        };
      }
      return value;
    });
};

export const getRomanizedText = (text: string): string => {
  return getRomanizedWords(text)
    .map((value) => {
      if (typeof value === "object") {
        return value.text;
      }
      return value;
    })
    .join(" ");
};
