import gql from "graphql-tag";

export const COLLECTION_INFO_FRAGMENT = gql`
  fragment CollectionInfo on Collection {
    id
    title
    description
    isLast
    collectionIndex
    totalLevels
    accomplished
    accessible
  }
`;

export const COLLECTION_REVIEW_INFO_FRAGMENT = gql`
  fragment CollectionReviewInfo on CollectionReview {
    id
    title
    isAccomplished
    totalChallenges
    accomplishedAt
  }
`;

export const COLLECTION_STRENGTH_INFO_FRAGMENT = gql`
  fragment CollectionStrengthInfo on CollectionStrength {
    id
    strength
    lastReviewAt
    lastIncreaseAt
    lastDecreaseAt
  }
`;

export const LEVEL_INFO_FRAGMENT = gql`
  fragment LevelInfo on Level {
    id
    title
    levelIndex
    avatar
    isLast
    accomplished
    accessible
    canAccess
    totalMaterials
  }
`;

export const LEVEL_PROGRESS_IN_COLLECTION_FRAGMENT = gql`
  fragment LevelProgressInCollection on Progress {
    id
    challenge {
      id
      challengeIndex
    }
  }
`;

export const PROGRESS_IN_COLLECTION_FRAGMENT = gql`
  fragment ProgressInCollection on Progress {
    id
    level {
      id
      levelIndex
    }
  }
`;

export const PROGRESS_IN_LEVEL_FRAGMENT = gql`
  fragment ProgressInLevelFragment on Progress {
    id
    level {
      id
      levelIndex
    }
    challenge {
      id
      challengeIndex
      type
      title
      path
    }
  }
`;

export const USER_SETTINGS_FRAGMENT = gql`
  fragment UserSettingsFragment on UserSettings {
    id
    romanizationEnabled
    timeZone
  }
`;

export const USER_SUBSCRIPTION_FRAGMENT = gql`
  fragment UserSubscriptionFragment on UserSubscription {
    id
    isPastDue
    isTrialing
    plan
    recurringInterval
    startedAt
    trialEndedAt
  }
`;

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserFragment on User {
    id
    username
    fullName
    email
    emailConfirmedAt
    emailConfirmationExpire
    avatar
    settings {
      ...UserSettingsFragment
    }
    subscription {
      ...UserSubscriptionFragment
    }
    signinProviders
    role
    onboardingState
  }
  ${USER_SETTINGS_FRAGMENT}
  ${USER_SUBSCRIPTION_FRAGMENT}
`;

export const CHALLENGE_INFO_FRAGMENT = gql`
  fragment ChallengeInfoFragment on Challenge {
    id
    challengeIndex
    title
    type
    path
    isLast
    accomplished
    accessible
    canAccess
  }
`;

export const CHALLENGE_RATING_INFO_FRAGMENT = gql`
  fragment ChallengeRatingInfoFragment on ChallengeRating {
    id
    rating
  }
`;

export const STREAK_FRAGMENT = gql`
  fragment StreakFragment on Streak {
    id
    startDate
    streakDays
  }
`;

export const ACCOMPLISHED_STATS_TOTAL_FRAGMENT = gql`
  fragment AccomplishedStatsTotalFragment on AccomplishedStatsTotal {
    letters
    vocabularies
    grammars
    quizzes
    readings
    reviews
    levels
    collections
  }
`;

export const CALENDAR_STREAK_DAY_FRAGMENT = gql`
  fragment CalendarStreakDayFragment on CalendarStreakDay {
    dayCode
    dayDate
    hasStreak
  }
`;

export const ACCOMPLISHED_CALENDAR_MONTH_FRAGMENT = gql`
  fragment AccomplishedCalendarMonthFragment on AccomplishedCalendarMonth {
    monthCode
    monthDate
    totalAccomplished {
      ...AccomplishedStatsTotalFragment
    }
  }
  ${ACCOMPLISHED_STATS_TOTAL_FRAGMENT}
`;
