import * as React from "react";
import {
  HStack,
  Circle,
  Icon,
  VStack,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";

import { RiFocus2Fill } from "react-icons/ri";

import { AudioPlayer as RawAudioPlayer } from "../../lesson-elements/audio-player";
import Romanize from "../../lesson-elements/romanize";

interface InteractiveExamplesProps {
  title?: string;
}

interface InteractiveExampleProps {
  value: string;
  translation: string;
  tts?: string;
}

export const InteractiveExamples: React.FC<
  React.PropsWithChildren<InteractiveExamplesProps>
> = (props) => {
  const { children, title = "Examples" } = props;

  return (
    <Box mb="1.5rem">
      <HStack spacing={2}>
        <Circle size={8} bg="purple.100">
          <Icon as={RiFocus2Fill} boxSize={5} color="purple.500" />
        </Circle>
        <Heading
          as="h3"
          color="purple.500"
          fontSize="0.9rem"
          fontWeight="bold"
          letterSpacing="wide"
        >
          {title}
        </Heading>
      </HStack>
      <VStack
        spacing={6}
        align="flex-start"
        pl={6}
        py={4}
        ml={4}
        pos="relative"
        _before={{
          content: '""',
          w: 0.5,
          position: "absolute",
          top: 2,
          bottom: 0,
          left: 0,
          bg: "purple.500",
        }}
      >
        {children}
      </VStack>
    </Box>
  );
};

export const Example: React.FC<InteractiveExampleProps> = (props) => {
  const { value, translation, tts } = props;

  return (
    <VStack spacing={1} flex={1} align="flex-start">
      <Text
        fontSize="1.1rem"
        fontWeight="bold"
        lineHeight="7"
        letterSpacing="wide"
        color="black"
      >
        <Romanize>{value.trim()}</Romanize>
      </Text>
      <Text as="span" fontSize="sm" color="gray.400" lineHeight="5">
        {translation.trim()}
      </Text>
      {tts ? (
        <Box w="100%" maxW={80}>
          <RawAudioPlayer
            src={tts}
            shouldDisplayPlaybackDuration={false}
            colorScheme="purple"
            size="sm"
            mt={2}
          />
        </Box>
      ) : null}
    </VStack>
  );
};
