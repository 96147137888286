import * as React from "react";
import { useDebounceFn } from "ahooks";
import * as runtime from "react/jsx-runtime";
import { VFile } from "vfile";
import { VFileMessage } from "vfile-message";
import { evaluate, EvaluateOptions } from "@mdx-js/mdx";

interface UseMdxProps {
  value: string;
}

interface State {
  value: string;
  file: (VFile & { result: any }) | null;
}

export const useMdx = (defaults: UseMdxProps) => {
  const [state, setState] = React.useState<State>({ ...defaults, file: null });
  const { run: setConfig } = useDebounceFn(
    async (config: Pick<State, "value">) => {
      const file = new VFile({ basename: "notion.mdx", value: config.value });

      const evaluateOptions = {
        ...runtime,
        useDynamicImport: true,
      } as unknown as EvaluateOptions;

      try {
        const evaluated = await evaluate(file, {
          ...evaluateOptions,
          development: import.meta.env.DEV,
        });

        file.result = evaluated.default;
      } catch (error) {
        const message =
          error instanceof VFileMessage
            ? error
            : new VFileMessage(error as Error);

        if (!file.messages.includes(message)) {
          file.messages.push(message);
        }

        message.fatal = true;
      }

      setState({ ...config, file });
    },
    { leading: true, trailing: true, wait: 500 },
  );

  return [state, setConfig] as const;
};
