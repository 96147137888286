import * as React from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Portal,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  VStack,
  useDisclosure,
  slideFadeConfig,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { AnimatedBox } from "@natanada/ui";

import { RiArrowDownSLine, RiPauseFill, RiPlayFill } from "react-icons/ri";

import {
  formatPlaybackDuration,
  useSoundPlayer,
} from "../lesson-elements/audio-player";
import {
  Duration,
  PlaybackDuration,
  Time,
} from "../lesson-elements/audio-player/playback-duration";

export interface ReadAloudProps {
  src: string;
}

const size = "sm";
const colorScheme = "brand";

export const ReadAloud: React.FC<ReadAloudProps> = (props) => {
  const { src } = props;
  const { isOpen, onClose, onOpen } = useDisclosure({
    onClose: handlePlayerClose,
  });
  const { duration, state, seek, toggle, pause } = useSoundPlayer({
    src,
    onPlay: onOpen,
  });

  // Pause the audio when closing the player
  function handlePlayerClose() {
    pause();
  }

  return (
    <React.Fragment>
      <Box py={2} mb="1.5rem">
        <Button
          onClick={toggle}
          isLoading={state.isLoading}
          size="xs"
          leftIcon={state.isPlaying ? <RiPauseFill /> : <RiPlayFill />}
        >
          {state.isPlaying ? "Pause" : "Listen"}
        </Button>
      </Box>
      <Portal>
        <AnimatePresence>
          {isOpen ? (
            <AnimatedBox
              {...(slideFadeConfig as Record<string, unknown>)}
              custom={{ offsetX: 0, offsetY: 80, reverse: true }}
              pos="fixed"
              left={0}
              right={0}
              bottom={0}
              zIndex="sticky"
            >
              <Container maxW="container.xl">
                <Box px={2} roundedTop="md" bgColor="white" shadow="card-lg">
                  <Stack direction="row" wrap="nowrap" spacing={4} py={2}>
                    <IconButton
                      aria-label="Play or Pause Audio"
                      onClick={toggle}
                      isLoading={state.isLoading}
                      isRound
                      icon={state.isPlaying ? <RiPauseFill /> : <RiPlayFill />}
                      colorScheme={colorScheme}
                      size={size}
                    />
                    <VStack
                      spacing={0}
                      justify="center"
                      flex={1}
                      pos="relative"
                    >
                      <Slider
                        aria-label="Player Progress"
                        min={0}
                        max={duration}
                        step={0.01}
                        defaultValue={0}
                        value={seek.value}
                        onChangeStart={seek.onSeekStart}
                        onChange={seek.onSeek}
                        onChangeEnd={seek.onSeekEnd}
                        isDisabled={state.isLoading}
                        focusThumbOnChange={false}
                        colorScheme={colorScheme}
                        size="md"
                        pos="relative"
                      >
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb boxSize={2} bg={`${colorScheme}.500`} />
                      </Slider>
                      <PlaybackDuration>
                        <Time>{formatPlaybackDuration(seek.value)}</Time>
                        <Duration>{formatPlaybackDuration(duration)}</Duration>
                      </PlaybackDuration>
                    </VStack>
                    <IconButton
                      aria-label="Close Audio Player"
                      icon={<RiArrowDownSLine />}
                      isRound
                      size="for-icon:xs"
                      colorScheme="gray"
                      alignSelf="center"
                      onClick={onClose}
                    />
                  </Stack>
                </Box>
              </Container>
            </AnimatedBox>
          ) : null}
        </AnimatePresence>
      </Portal>
    </React.Fragment>
  );
};
