import { css } from "styled-components/macro";

const headingBase = css`
  font-family: inherit;
  color: inherit;
  line-height: 1.5;
  &:first-child {
    margin-top: 0;
  }
`;

const h1 = css`
  > h1 {
    ${headingBase}
    line-height: 1.1;
    font-family: "Lora", serif;
    font-size: 3rem;
    font-style: italic;
    letter-spacing: -0.066875rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
`;

const h2 = css`
  > h2 {
    ${headingBase}
    font-size: 2.25rem;
    letter-spacing: -0.049375rem;
    font-weight: 600;
    margin: 3rem 0rem 1rem;
  }
`;

const h3 = css`
  > h3 {
    ${headingBase}
    font-size: 1.5rem;
    letter-spacing: -0.029375rem;
    margin: 1.6rem 0rem 0.6rem;
  }
`;

const h4 = css`
  > h4 {
    ${headingBase}
    font-size: 1.25rem;
    letter-spacing: -0.020625rem;
    margin: 1.5rem 0rem 0.5rem;
  }
`;

const h5 = css`
  > h5 {
    ${headingBase}
    font-size: 1rem;
    letter-spacing: -0.01125rem;
  }
`;

const h6 = css`
  > h6 {
    ${headingBase}
    font-size: .875rem;
    letter-spacing: -0.005625rem;
  }
`;

const p = css`
  > p {
    font-family: inherit;
    color: #3c4257;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
    word-break: break-word;
    margin: 0 0 1.5rem;
  }
`;

const strong = css`
  strong {
    color: #19193b;
    font-weight: 700;
  }
`;

const em = css`
  em {
    font-family: "Lora", serif;
    font-style: italic;
  }
`;

const a = css`
  a {
    text-decoration: underline;
    color: #2141ce;
    text-decoration-color: hsla(229, 83%, 58%, 0.3);
    &:hover {
      background-image: linear-gradient(90deg, #ff758c, #ff7eb3);
      background-clip: text;
      -webkit-background-clip: text;
      text-fill-color: transparent;
      -webkit-text-fill-color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
  }
`;

const list = css`
  ul,
  ol {
    margin: 0rem 0rem 1.5rem 1.5rem;
    list-style: none;
    counter-reset: li-counter;
    color: #3c4257;
  }

  ul li {
    text-indent: -1.1rem;
    margin: 0 0 5px;
    &::before {
      content: "•";
      font-size: 1.2rem;
      margin: 0 0.5rem 0 0;
      color: #f7627b;
    }
  }

  ol li {
    text-indent: -1.1rem;
    margin: 0 0 5px;
    counter-increment: li-counter;
    &::before {
      content: counter(li-counter) ".";
      font-family: "Lora", serif;
      font-style: normal;
      font-size: 1.1rem;
      color: #f7627b;
      font-weight: 700;
      margin: 0 0.5rem 0 0;
    }
  }
`;

const blockquote = css`
  blockquote {
    quotes: "“" "”" "‘" "’";
    position: relative;
    padding: 0.25rem 0 0.25rem 1.5rem;
    margin: 0 0 1.5rem;
    & > :first-child::before {
      content: open-quote;
      color: #f7627b;
      font-size: 2rem;
    }
    & > :last-child::after {
      content: close-quote;
      color: #f7627b;
      font-size: 2rem;
      vertical-align: middle;
    }

    p {
      font-family: "Lora", serif;
      font-size: 1.1rem;
      font-weight: 500;
      font-style: italic;
      letter-spacing: -0.02rem;
      line-height: 1.2;
    }
    p:last-child {
      margin: 0rem;
    }
  }
`;

export const globalElements = css`
  ${h1}
  ${h2}
  ${h3}
  ${h4}
  ${h5}
  ${h6}
  ${p}
  ${strong}
  ${em}
  ${a}
  ${list}
  ${blockquote}
`;
