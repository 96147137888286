import * as React from "react";

import { DotsContainer, Dot } from "./style";

interface LoadingDotsProps {
  size?: number;
  color?: string;
  style?: React.CSSProperties;
}

const LoadingDots: React.FC<LoadingDotsProps> = ({
  color = "#395aed",
  size = 4,
  ...rest
}) => (
  <DotsContainer color={color} size={size} {...rest}>
    <Dot />
    <Dot />
    <Dot />
  </DotsContainer>
);

export default LoadingDots;
