import * as React from "react";
import { useLocalStorage } from "@rehooks/local-storage";

const NOTION_STORAGE_KEY = "NOTION-PREVIEW";

export interface NotionPreview {
  /**
   * Notion CMS ID
   */
  id: string;
  /**
   * Notion value
   */
  value: string;
}

export const useSyncedNotionPreview = () => {
  const [preview, setPreview] =
    useLocalStorage<NotionPreview>(NOTION_STORAGE_KEY);

  // Set preview
  const addPreview = React.useCallback(
    (id: string, value: string) => {
      setPreview({ id, value });
    },
    [setPreview],
  );

  return {
    preview,
    addPreview,
  };
};
