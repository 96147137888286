import React from "react";
import styled from "styled-components/macro";
import {
  Player,
  PlayerEvent,
  PlayerState,
} from "@lottiefiles/react-lottie-player";

import LoadingDots from "../loading-dots";
import { dataAttr } from "../../helpers/data-attr";

interface AnimationProps {
  src: string;
  caption: string;
  inline?: boolean;
  loop?: boolean;
}

const URL_FALLBACK =
  "https://assets4.lottiefiles.com/packages/lf20_1owgihfi.json";

export const Animation: React.FC<AnimationProps> = ({
  src,
  caption,
  inline = false,
  loop = false,
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const playerInstance = React.useRef<Player>(null!);

  const handlePlay = () => {
    if (playerInstance.current.state.playerState === PlayerState.Playing) {
      return;
    }
    playerInstance.current.play();
    playerInstance.current.state.instance?.goToAndPlay(0, true);
  };

  // const handleStop = () => {
  //   const lastFrame = playerInstance.current.state.instance?.totalFrames ?? 0;
  //   playerInstance.current.state.instance?.goToAndStop(lastFrame, true);
  // };

  const handleOnEvent = (event: PlayerEvent) => {
    if (event === PlayerEvent.Load) {
      setIsLoading(false);
    }
  };

  const playbackHandlers = {
    onMouseEnter: handlePlay,
    onClick: handlePlay,
    // onMouseLeave: handleStop,
  };

  return (
    <FigureStyle data-inline={dataAttr(inline)}>
      <div {...(loop ? {} : playbackHandlers)}>
        <Player
          id="lottie-player"
          ref={playerInstance}
          autoplay
          keepLastFrame
          loop={loop}
          controls={false}
          onEvent={handleOnEvent}
          src={src || URL_FALLBACK}
          style={{ paddingTop: isLoading ? "55%" : 0 }}
        >
          {isLoading ? (
            <LoadingContainer>
              <LoadingDots />
            </LoadingContainer>
          ) : null}
        </Player>
      </div>
      <FigcaptionStyle>{caption}</FigcaptionStyle>
    </FigureStyle>
  );
};

const FigureStyle = styled.figure`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 2.5rem 1rem 1.5rem 0rem;
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 100%;
    height: 100%;
    background-color: rgba(194, 194, 254, 0.2);
    border-radius: 12px;
    padding-bottom: 1rem;
    z-index: 1;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 100%;
    min-height: calc(270px + 2.2rem);
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 30px rgba(16, 14, 23, 0.25);
    z-index: 2;
  }
  & .lf-player-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &[data-inline] {
    margin-top: 1.5rem;
    margin-right: 0;
    &::before {
      display: none;
    }
    > div {
      box-shadow: none;
    }
    > figcaption {
      display: none;
    }
  }
`;

const FigcaptionStyle = styled.figcaption`
  position: relative;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  color: #3c4257;
  padding: 1rem;
  margin-left: 1rem;
  z-index: 2;
`;

const LoadingContainer = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
`;
