import * as React from "react";

import {
  AudioPlayer as RawAudioPlayer,
  AudioPlayerProps,
} from "../lesson-elements/audio-player";

export const AudioPlayer: React.FC<AudioPlayerProps> = (props) => (
  <RawAudioPlayer mb="1.5rem" {...props} />
);
