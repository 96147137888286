import * as React from "react";
import styled from "styled-components/macro";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";

import "react-medium-image-zoom/dist/styles.css";

export const Zoom: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isZoomed, setIsZoomed] = React.useState(false);

  const handleZoomIn = React.useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleZoomChange = React.useCallback((shouldZoom: boolean) => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <ControlledZoom
      zoomMargin={16}
      zoomZindex={999}
      isZoomed={isZoomed}
      onZoomChange={handleZoomChange}
      wrapStyle={{ display: "flex", alignItems: "center" }}
    >
      <ZoomChildrenWrapper
        onClick={handleZoomIn}
        role="button"
        tabIndex={-1}
        onKeyDown={handleZoomIn}
      >
        {children}
      </ZoomChildrenWrapper>
    </ControlledZoom>
  );
};

const ZoomChildrenWrapper = styled.div`
  flex: 1;
  cursor: zoom-in;
`;
