import * as React from "react";
import { Text } from "@chakra-ui/react";
import styled from "styled-components/macro";

interface RomanizedWordProps {
  value: string;
  word: string;
}

export const RomanizedWord: React.FC<RomanizedWordProps> = ({
  value,
  word,
}) => {
  return (
    <Romanization>
      <Text
        as="span"
        display="block"
        fontSize="0.62rem"
        fontWeight="light"
        color="gray.500"
        lineHeight="1"
        wordBreak="keep-all"
        letterSpacing="0.022rem"
        textAlign="center"
        padding="0.2rem"
      >
        {value}
      </Text>
      <KoreanWord>{word}</KoreanWord>
    </Romanization>
  );
};

const Romanization = styled.div`
  display: inline-block;
  position: relative;
  line-height: inherit;
  text-indent: 0;
`;

const KoreanWord = styled.div`
  font-size: inherit;
  font-weight: inherit;
  line-height: 100%;
  text-align: center;
  word-break: keep-all;
`;
