import styled, { keyframes } from "styled-components/macro";

export const DotsContainer = styled.span<{ size: number; color: string }>`
  display: inline-flex;
  align-items: center;
  height: auto;
  pointer-events: none;
  user-select: none;
  line-height: 100%;
  > span {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background-color: ${(props) => props.color};
  }
`;

export const blinkAnimation = keyframes`
0% {
  opacity: 0.2;
}
20% {
  opacity: 1;
}
100% {
  opacity: 0.2;
} 
`;

export const Dot = styled.span`
  animation-name: ${blinkAnimation};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  display: inline-block;
  line-height: 100%;
  border-radius: 50%;
  margin: 0px 1px;
  &:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`;
