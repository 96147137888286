/**
 * Format playback duration (in seconds)
 */
export function formatPlaybackDuration(time: number) {
  const roundedTime = Math.round(time);
  const minutes = Math.floor(roundedTime / 60) || 0;
  const seconds = roundedTime - minutes * 60 || 0;

  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}
