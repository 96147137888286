import * as React from "react";

import {
  AudioButton as RawAudioButton,
  AudioButtonProps,
} from "../lesson-elements/audio-button";

export const AudioButton: React.FC<AudioButtonProps> = (props) => (
  <RawAudioButton m={4} mb={6} {...props} />
);
