import React from "react";
import { AspectRatio, Image as ChakraImage } from "@chakra-ui/react";
import styled from "styled-components/macro";

import { Zoom } from "./zoom-element";
import { ImageFallback } from "../image-fallback";

interface ImageProps {
  src: string;
  caption: string;
}

export const Image: React.FC<ImageProps> = ({ src, caption }) => {
  return (
    <Zoom>
      <FigureStyle>
        <ChakraImage
          src={src}
          alt={caption}
          fallback={
            <AspectRatio
              ratio={16 / 9}
              bg="white"
              overflow="hidden"
              borderRadius="6px"
              boxShadow="0 0 30px rgba(16, 14, 23, 0.25)"
              zIndex={2}
            >
              <ImageFallback />
            </AspectRatio>
          }
          style={{
            position: "relative",
            width: "100%",
            height: "auto",
            objectFit: "cover",
            zIndex: 2,
            borderRadius: "6px",
            boxShadow: "0 0 30px rgba(16, 14, 23, 0.25)",
          }}
        />
        <FigcaptionStyle>{caption}</FigcaptionStyle>
      </FigureStyle>
    </Zoom>
  );
};

const FigureStyle = styled.figure`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 2.5rem 1rem 1.5rem 0rem;
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 100%;
    height: 100%;
    background-color: rgba(194, 194, 254, 0.2);
    border-radius: 12px;
    padding-bottom: 1rem;
    z-index: 1;
  }
`;

const FigcaptionStyle = styled.figcaption`
  position: relative;
  font-size: 0.8rem;
  letter-spacing: 0.02rem;
  color: #3c4257;
  padding: 1rem;
  margin-left: 1rem;
  z-index: 2;
`;
