import * as React from "react";
import styled from "styled-components/macro";
import { Text } from "@chakra-ui/react";

import { Romanize } from "./romanize";
import { IconAudioButton } from "../lesson-elements/audio-button";

interface InteractiveWordProps {
  translation: string;
  tts?: string;
  children: string;
}

export const InteractiveWord: React.FC<InteractiveWordProps> = ({
  children,
  translation,
  tts,
}) => {
  return (
    <Wrapper>
      <Text
        fontSize="1.1rem"
        fontWeight="bold"
        lineHeight="7"
        letterSpacing="wide"
        color="black"
      >
        <Romanize>{children}</Romanize>
      </Text>
      <Text as="span" fontSize="sm" color="gray.400" lineHeight="5" ml={2}>
        / {translation}
      </Text>
      {tts && (
        <IconAudioButton
          src={tts}
          size="sm"
          pos="absolute"
          right="-0.6rem"
          bottom="-0.6rem"
          boxShadow="base"
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
  background-color: white;
  padding: 1rem 2.5rem 1rem 1rem;
  margin: 1rem;
  border: 1.5px solid #ccd7ee;
  border-radius: 4px;
  margin-bottom: 1.5rem;
`;
