import * as React from "react";
import { HStack, Box, useCallbackRef, useUpdateEffect } from "@chakra-ui/react";
import { valueToPercent } from "@chakra-ui/utils";
import inRange from "lodash/inRange";

import { ReadingAudioChapter } from "../../../types/reading";

export interface ChaptersProps {
  audioSeek: number;
  audioDuration: number;
  chapters: ReadingAudioChapter[];
  onChapterStart: (chapter: ReadingAudioChapter) => void;
  onChapterEnd: (chapter: ReadingAudioChapter) => void;
}

export interface ChapterProps {
  length: number;
  isPlaying: boolean;
  onChapterStart: () => void;
  onChapterEnd: () => void;
}

export const Chapters: React.FC<ChaptersProps> = (props) => {
  const {
    audioSeek: seek,
    audioDuration: duration,
    chapters,
    onChapterStart,
    onChapterEnd,
  } = props;
  return (
    <HStack
      spacing={0}
      h="100%"
      w="100%"
      pos="absolute"
      top="50%"
      left={0}
      right={0}
      transform="translateY(-50%)"
      color="white"
    >
      {chapters.map((chapter) => {
        const isPlaying = inRange(seek, chapter.start + 0.01, chapter.end);
        const chapterLength = valueToPercent(
          chapter.end - chapter.start,
          0,
          duration,
        );
        return (
          <Chapter
            key={chapter.id}
            length={chapterLength}
            isPlaying={isPlaying}
            onChapterStart={() => onChapterStart(chapter)}
            onChapterEnd={() => onChapterEnd(chapter)}
          />
        );
      })}
    </HStack>
  );
};

export const Chapter: React.FC<ChapterProps> = React.memo(
  function Chapter(props) {
    const { length, isPlaying, onChapterStart, onChapterEnd } = props;
    const onChapterStartProp = useCallbackRef(onChapterStart);
    const onChapterEndProp = useCallbackRef(onChapterEnd);

    useUpdateEffect(() => {
      if (isPlaying) {
        onChapterStartProp();
      } else {
        onChapterEndProp();
      }
    }, [isPlaying]);

    return (
      <Box
        maxW={`calc(${length}% + 2px)`}
        w="100%"
        h="100%"
        bg="transparent"
        borderRightWidth={4}
        borderRightColor="currentcolor"
        _last={{ border: "none" }}
      />
    );
  },
);
