import * as React from "react";
import {
  IconButton,
  Stack,
  StackProps,
  VStack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  ThemeTypings,
} from "@chakra-ui/react";

import { RiPauseFill, RiPlayFill } from "react-icons/ri";

import { useSoundPlayer } from "./use-sound-player";
import { PlaybackDuration, Time, Duration } from "./playback-duration";
import { formatPlaybackDuration } from "./utils";
import { Chapters, ChaptersProps } from "./chapters";

export interface AudioPlayerProps
  extends Partial<Omit<ChaptersProps, "audioDuration" | "audioSeek">>,
    Omit<StackProps, "children"> {
  src: string;
  onPlay?: () => void;
  onPause?: () => void;
  colorScheme?: ThemeTypings["colorSchemes"] | string;
  size?: ThemeTypings["components"]["Slider"]["sizes"];
  shouldDisplayPlaybackDuration?: boolean;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = (props) => {
  const {
    src,
    colorScheme = "orange",
    size = "md",
    chapters = [],
    shouldDisplayPlaybackDuration = true,
    onChapterEnd,
    onChapterStart,
    onPlay,
    onPause,
    ...stackProps
  } = props;
  const { duration, state, seek, toggle } = useSoundPlayer({
    src,
    onPlay,
    onPause,
  });

  const shouldDisplayChapters =
    !state.isLoading && chapters.length > 0 && duration > 1;
  return (
    <Stack direction="row" wrap="nowrap" spacing={4} py={2} {...stackProps}>
      <IconButton
        aria-label="Play or Pause Audio"
        onClick={toggle}
        isLoading={state.isLoading}
        isRound
        icon={state.isPlaying ? <RiPauseFill /> : <RiPlayFill />}
        colorScheme={colorScheme}
        size={size}
      />
      <VStack spacing={0} justify="center" flex={1} pos="relative">
        <Slider
          aria-label="Player Progress"
          min={0}
          max={duration}
          step={0.01}
          defaultValue={0}
          value={seek.value}
          onChangeStart={seek.onSeekStart}
          onChange={seek.onSeek}
          onChangeEnd={seek.onSeekEnd}
          isDisabled={state.isLoading}
          focusThumbOnChange={false}
          colorScheme={colorScheme}
          size={size}
          pos="relative"
        >
          <SliderTrack>
            <SliderFilledTrack />
            {/* AUDIO CHAPTERS */}
            {shouldDisplayChapters ? (
              <Chapters
                audioSeek={seek.value}
                audioDuration={duration}
                chapters={chapters}
                onChapterEnd={onChapterEnd!}
                onChapterStart={onChapterStart!}
              />
            ) : null}
          </SliderTrack>
          <SliderThumb boxSize={2} bg={`${colorScheme}.500`} />
        </Slider>
        {shouldDisplayPlaybackDuration ? (
          <PlaybackDuration>
            <Time>{formatPlaybackDuration(seek.value)}</Time>
            <Duration>{formatPlaybackDuration(duration)}</Duration>
          </PlaybackDuration>
        ) : null}
      </VStack>
    </Stack>
  );
};
