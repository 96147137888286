export const BLOCKS_UNICODE = {
  HANGUL_SYLLABLES: [0xac_00, 0xd7_af],
  HANGUL_JAMO: [0x11_00, 0x11_ff],
  _HANGUL_JAMO: {
    CHOSEONG: [0x11_00, 0x11_12],
    RIEUL_JONGSEONG: [0x11_af, 0x11_b6],
    JUNGSEONG: [0x11_61, 0x11_75],
    JONGSEONG: [0x11_a8, 0x11_c2],
  },
  HANGUL_COMPATIBILITY_JAMO: [0x31_30, 0x31_8f],
  _HANGUL_COMPATIBILITY_JAMO: {
    RIEUL: [0x31_3a, 0x31_40],
    JAEUM: [0x31_31, 0x31_4e],
    MOEUM: [0x31_4f, 0x31_63],
  },
  HANGUL_JAMO_EXTENDED_A: [0xa9_60, 0xa9_7f],
  HANGUL_JAMO_EXTENDED_B: [0xd7_b0, 0xd7_ff],
  BASIC_LATIN: [0x00_00, 0x00_7f],
  CJK_UNIFIED_IDEOGRAPHS: [0x4e_00, 0x9f_ff],
  CJK_SYMBOLS_AND_PUNCTUATION: [0x30_00, 0x30_3f],
};
