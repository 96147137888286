import { Howler, Howl } from "howler";

type HowlerExtended = typeof Howler & {
  _howls?: Howl[];
};

/**
 * Pause all playing sounds
 */
export const pauseAllSounds = () => {
  (Howler as HowlerExtended)?._howls?.forEach((howl) => {
    const isCurrentPlaying = howl?.playing();
    if (isCurrentPlaying) {
      howl?.pause();
    }
  });
};

/**
 * Stop all playing sounds
 */
export const stopAllSounds = () => {
  (Howler as HowlerExtended)?._howls?.forEach((howl) => {
    const isCurrentPlaying = howl?.playing();
    if (isCurrentPlaying) {
      howl?.stop();
    }
  });
};
