import * as React from "react";
import { AspectRatio, Image as ChakraImage } from "@chakra-ui/react";

import { Zoom } from "./zoom-element";
import { ImageFallback } from "../image-fallback";

interface IllustrationProps {
  src: string;
  caption: string;
}

export const Illustration: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ src, caption }) => {
  return (
    <Zoom>
      <ChakraImage
        src={src}
        alt={caption}
        fallback={
          <AspectRatio ratio={16 / 9} mb="1.5rem">
            <ImageFallback />
          </AspectRatio>
        }
        style={{
          position: "relative",
          width: "100%",
          height: "auto",
          objectFit: "cover",
          margin: "0rem auto 1.5rem",
        }}
      />
    </Zoom>
  );
};
