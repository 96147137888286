import * as React from "react";
import { reporter } from "vfile-reporter";
import { statistics, Statistics } from "vfile-statistics";
import { ErrorBoundary } from "react-error-boundary";

import * as UI from "web-app/components/mdx-elements";
import { NotionContainer } from "web-app/notions";

import { useMdx } from "../../hooks/use-mdx";
import {
  ErrorFallback,
  VfileErrorFallback,
  VfileStatisticsFallback,
} from "../../error-boundary";

export const MDXWidgetPreview: React.FC<{ value: string }> = (props) => {
  const { value } = props;
  const [mdxState, setMdxState] = useMdx({
    value,
  });
  const mdxSetter = React.useRef(setMdxState).current;

  //
  const mdxProps = React.useMemo(
    () => ({
      components: { ...UI },
    }),
    [],
  );

  React.useEffect(() => {
    mdxSetter({ value });
  }, [mdxSetter, value]);

  const stats = mdxState.file
    ? statistics(mdxState.file as never)
    : ({} as Partial<Statistics>);

  // Create a preview component that can handle errors with try-catch block
  // for catching invalid JS expressions errors that ErrorBoundary cannot catch.
  const Preview = React.useCallback(() => {
    try {
      const preview = mdxState.file?.result?.(mdxProps);
      return <React.Fragment>{preview}</React.Fragment>;
    } catch (error) {
      return <VfileErrorFallback error={error as Error} />;
    }
  }, [mdxProps, mdxState.file]);

  return (
    <NotionContainer id="notion-preview">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {mdxState.file && mdxState.file.result ? <Preview /> : null}
        {mdxState.file && stats?.fatal ? (
          <VfileStatisticsFallback error={reporter(mdxState.file as never)} />
        ) : null}
      </ErrorBoundary>
    </NotionContainer>
  );
};
