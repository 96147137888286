import * as React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";

import { Logo } from "../logo";

export const ImageFallback: React.FC<FlexProps> = (props) => {
  return (
    <Flex
      align="center"
      justify="center"
      w="100%"
      h="100%"
      bg="gray.100"
      p={2}
      {...props}
    >
      <Logo
        title="Loading..."
        variant="mark"
        size="lg"
        sx={{ color: "gray.200" }}
      />
    </Flex>
  );
};
