import { MutationHookOptions } from "@apollo/client";
import {
  createWithEqualityFn,
  useStoreWithEqualityFn,
} from "zustand/traditional";
import { shallow } from "zustand/shallow";

import { useUpdateAccountSettingsMutation } from "../generated/apollo-hooks";
import type {
  UserSettingsFragmentFragment,
  UpdateAccountSettingsMutation,
  Exact,
  EditUserSettingsInput,
} from "../generated/apollo-hooks";
import { CURRENT_USER } from "../graphql/queries/user";

interface UserSettingsStore {
  settings: UserSettingsFragmentFragment;
  set(settings: UserSettingsFragmentFragment): void;
}

const defaultSettings: UserSettingsFragmentFragment = {
  id: "",
  romanizationEnabled: false,
  timeZone: "",
};

export const userSettingsStore = createWithEqualityFn<UserSettingsStore>()(
  (setStore) => ({
    settings: defaultSettings,
    set: (settings) =>
      setStore((prev) => ({
        settings: { ...prev.settings, ...settings },
      })),
  }),
);

/**
 * Access user settings hook
 */
export const useUserSettings = () => {
  const [settings, setSettings] = useStoreWithEqualityFn(
    userSettingsStore,
    (state) => [
      state.settings,
      (value: UserSettingsFragmentFragment) => state.set(value),
    ],
    shallow,
  );
  return [settings, setSettings] as const;
};

/**
 * Update user settings mutation hook
 */
export const useUpdateUserSettings = (
  mutationOptions?: MutationHookOptions<
    UpdateAccountSettingsMutation,
    Exact<{
      input: EditUserSettingsInput;
    }>
  >,
) => {
  const { onCompleted, onError, ...options } = mutationOptions || {};
  const [, setSettings] = useUserSettings();
  const mutationTuple = useUpdateAccountSettingsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: CURRENT_USER }],
    onCompleted: (result) => {
      setSettings(result.editUserSettings.settings);
      onCompleted?.(result);
    },
    onError,
    ...options,
  });

  return mutationTuple;
};
