import * as React from "react";

import { isValidText, getRomanizedWords } from "./romanize.helpers";
import { useUserSettings } from "../../../stores/user-settings";
import { ReturnedHangulWithRuby } from "../../../lib/hangul";

export const useRomanize = (text: string, isActivated?: boolean) => {
  const [settings] = useUserSettings();
  const isRomanizeActivated = isActivated ?? settings.romanizationEnabled;

  const isValid = isValidText(text);
  const words = React.useRef<ReturnedHangulWithRuby[] | null>();

  React.useMemo(() => {
    if (isRomanizeActivated && isValid) {
      words.current = getRomanizedWords(text);
      return;
    }
    words.current = null;
  }, [isRomanizeActivated, isValid, text]);

  return {
    isActivated: isRomanizeActivated,
    isValid,
    result: words.current,
  } as const;
};
