import * as React from "react";

import { useRomanize } from "./use-romanize.hook";
import { RomanizedWord } from "./word";

interface RomanizeProps {
  isActivated?: boolean;
  children: string;
}

const Romanize: React.FC<RomanizeProps> = ({
  children,
  isActivated: isActivatedProp,
}) => {
  const { isActivated, isValid, result } = useRomanize(
    children,
    isActivatedProp,
  );

  if (!isValid) {
    throw new Error(
      "Please provide a string as children to the Romanize component",
    );
  }

  // Return the text without romanization
  if (!isActivated) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <React.Fragment>
      {result?.map((word, index) => {
        if (typeof word === "object") {
          return (
            <RomanizedWord
              // eslint-disable-next-line react/no-array-index-key
              key={`${word.text}-${index}`}
              value={word.text}
              word={word.ruby}
            />
          );
        }
        return word;
      })}
    </React.Fragment>
  );
};

export default Romanize;
